import React, { useEffect, useState } from "react";
import logo from "../../asset/newlogo.png";
import checkboxbackground from "../../asset/checkboxbackground.png";
import check from "../../asset/checkbox Tick.png"
import close from "../../asset/close.png";
import { BtnClickSound } from "../element/audiofile";
import { AppVersion, mysocket } from "../../config/config";


//import { useNavigate } from "react-router-dom";



const RegisterPage = (props) => {

    // const routerHistory = useNavigate();

    useEffect(()=>{
        // localStorage.removeItem("username");
        // localStorage.removeItem("id");
        localStorage.clear();
    //     setUsernmae(localStorage.getItem("_username"));
    //    setPassword(localStorage.getItem("_password"));
        return(()=>{});
    },[]);


 


    const [flag, setflag] = useState(true)

    const [username, setUsernmae] = useState();
    const [password, setPassword] = useState();
    const [name, setname] = useState();
    const [mobile, setmobile] = useState();
    const [refid, setrefid] = useState();





    const [savePass, setSavePass] = useState(false);
    const [showPop, setShowPop] = useState(false);
  

    let checkedfun = () => {
        if (flag === true) {
            document.getElementsByClassName("chBox")[0].style.display = "block";
            // document.getElementsByClassName("chBox1")[0].style.display = "none";
            setflag(false);
            setSavePass(false);
        } else {
            document.getElementsByClassName("chBox")[0].style.display = "none";
            document.getElementsByClassName("chBox1")[0].style.display = "block";
            setflag(true);
            setSavePass(true);
        }
    
        // Log the updated value of flag after state update
        console.log(flag);
    }


    const handleLoginResponses = (e) => {
        var data = e?.respo;
    
        if (data && data.str === "ERR") {
          

            props.setModelText("Username Password is Wrong");
            props.seterrorpopup(true);
    
            // Remove the event listener after the first occurrence
            mysocket.off("ws_login_resp", handleLoginResponse);
        }


        if (data && data.str === "OK") {

            console.log(data["regID"]);           

            localStorage.setItem("id",data["regID"]);
            localStorage.setItem("username",username);

            if(!flag===true)
            {
 
                localStorage.setItem("_username",username);
                localStorage.setItem("_password",password);
            }
            if(data["F"]==='1')
            {
                props.setAlreadyLoginPopup(true);
            }
            else
            {
                props.screenRoute("/lobby");
            }
           //   
            // Remove the event listener after the first occurrence
            mysocket.off("ws_login_resp", handleLoginResponse);
        }

    };

     
    const handleLoginResponse = (e) => {
        var data = e;

        console.log("stange 5");
        console.log(e);
    
        if (data && data.STR === "ERR") {
          

            props.setModelText("Something is Wrong Try Again");
            props.seterrorpopup(true);
    
            // Remove the event listener after the first occurrence
            mysocket.off("ws_resgisterUser_respo", handleLoginResponse);
        }


        if (data && data.STR === "OK") {
            console.log(data);
            // props.screenRoute("/login");
            mysocket.emit("ws_login",{"username":username,"password":password});
            mysocket.on("ws_login_resp", handleLoginResponses);
            // console.log(data["regID"]);
            // localStorage.setItem("id",data["regID"]);
            // localStorage.setItem("username",username);
            // if(!flag===true)
            // {
            //     localStorage.setItem("_username",username);
            //     localStorage.setItem("_password",password);
            // }
            // if(data["F"]==='1')
            // {
            //     props.setAlreadyLoginPopup(true);
            // }
            // else
            // {
            //     props.screenRoute("/lobby");
            // }
            //   
            // Remove the event listener after the first occurrence
            mysocket.off("ws_resgisterUser_respo", handleLoginResponse);
        }

    };

    const loginUser = () => {
        BtnClickSound();
        console.log("stange 1");
        mysocket.on("connect", (e) => {
            console.log("Connected to Server");
        });
        mysocket.on("connect_error", (error) => {
            alert("Error to connect with socket: "+ error.message);
            console.error("Socket connection failed:", error);
            // Handle connection failure here
        });

      //  routerHistory("/lobby");

    //   props.setActivePage("/lobby");

    if(!username || !password || !name || !mobile || !refid)
    {
        props.setModelText("Enter Username and Password");
        props.seterrorpopup(true);
        console.log("stange 2");
        return;
    }



    mysocket.emit("ws_resgisterUser",username,name,password,mobile,refid);
    // Attach the event handler function to the "ws_login_resp" event
    mysocket.on("ws_resgisterUser_respo", handleLoginResponse);
    }

    const closeHandle=()=>{

        if (window.cordova && window.cordova.platformId === 'android') {
            navigator.app.exitApp();
          }

         else  if (window.process && window.process.type === 'renderer') {
            window.close();
          }
          else 
          {
            window.close();
          }
       

    }

    return (
        <>
            <div className="fgaLoginMain">
                <div className="loginMain">
                <button id="closeBtn" className="windoClose" onClick={()=>{BtnClickSound(); closeHandle(); }}>
                        <img src={close} />
                    </button>
                    <div class="languageSelection">
                        <select id="MyLangSelect">
                            <option value="English"> English </option>
                            <option value="తెలుగు"> తెలుగు </option>
                            <option value="বাংলা"> বাংলা </option>
                            <option value="मराठी"> मराठी </option>
                            <option value="हिंदी"> हिंदी </option>
                            <option value="ਪੰਜਾਬੀ"> ਪੰਜਾਬੀ </option>
                            <option value="ગુજરાતી"> ગુજરાતી </option>
                        </select>
                    </div>
                    <div className="loginDiv">
                        <div class="fd inp_elm logDf">
                            
                            <div class="fd df">
                                <label class="fieldLabel" id="username" for="userName" style={{ fontSize: "60px" }}> Username </label>
                                {/* <span class="funFx">  </span> */}
                                <input  pattern="^[0-9]*$"  name="username" placeholder="Username" id="fgaUserName"   onChange={(e) => { setUsernmae(e.target.value) }} autocomplete="off" style={{ fontSize: "50px" }}></input>
                            </div>
                            <div class="fd df m_t_5">
                                <label class="fieldLabel" id="password" for="password" style={{ fontSize: "60px" }}>
                                    Name </label>
                                <input type="text" placeholder="Name" name="password" id="fgaPassword"  autocomplete="off"  style={{ fontSize: "50px" }} onChange={(e) => { setname(e.target.value) }} ></input>
                            </div>
                            <div class="fd df m_t_5">
                                <label class="fieldLabel" id="password" for="password" style={{ fontSize: "60px" }}>
                                    Password </label>
                                <input type="password" placeholder="Password" name="password" id="fgaPassword"  autocomplete="off"  onChange={(e) => { setPassword(e.target.value) }} inputMode="numeric" style={{ fontSize: "50px" }}></input>
                            </div>
                            {/* <div class="fd df m_t_5">
                                <label class="fieldLabel" id="password" for="password" style={{ fontSize: "60px" }}>
                                    Email </label>
                                <input type="email" placeholder="Email" name="password" id="fgaPassword"  autocomplete="off"   style={{ fontSize: "50px" }}></input>
                            </div> */}
                            <div class="fd df m_t_5">
                                <label class="fieldLabel" id="password" for="password" style={{ fontSize: "60px" }}>
                                    Mobile </label>
                                <input type="number" maxlength="10" placeholder="Mobile" name="password" id="fgaPassword"  autocomplete="off"   style={{ fontSize: "50px" }} onChange={(e) => { setmobile(e.target.value) }}></input>
                            </div>
                            <div class="fd df m_t_5">
                                <label class="fieldLabel" id="password" for="password" style={{ fontSize: "60px" }}>
                                 Referal id </label>
                                <input type="text" placeholder="Referal id" name="password" id="fgaPassword"  autocomplete="off"   style={{ fontSize: "50px" }} onChange={(e) => { setrefid(e.target.value) }}></input>
                            </div>
                            {/* <div class="fd m_t_30 ">
                                <label class="checkBoxLable" id="chkBxLbl">
                                    <img src={check} alt="check-box-with-check-emoj" className="chBox" />
                                    <img src={checkboxbackground} alt="check-box-with-check-emoj" className="chBox1" />
                                    <input class="checkbox_1" type="checkbox" name="checkbox" id="rememberMe" value="false" checked="" onChange={() => checkedfun()} />
                                    &nbsp; Remember password
                                </label>
                            </div> */}
                            <div class="fd logBtns">
                                <div class="row">
                                    <div class="col-5">
                                        <button id="submitBtn" type="submit" class="btn_1 fd logSubmt" value="Login" onClick={() =>{  props.screenRoute("/login");}}>Login
                                        </button>
                                    </div>
                                    <div class="col-0">
                                    </div><div class="col-5">
                                        <button id="regBtn" type="submit" class="btn_1 fd logSubmt" value="Login" onClick={()=>{loginUser()}}>
                                            Register</button>
                                    </div>
                                </div>
                            </div>
                            <div class="fd logBtns">
                                <div class="row">
                                    <div class="col-5">
                                        <button id="frgPwd" disabled="" type="submit" class="btn_1 fd disableBtn" value="Login">
                                            Forgot password </button>
                                    </div>
                                    <div class="col-0">
                                    </div>
                                    <div class="col-5">
                                        <button id="frgPin" disabled="" type="submit" class="btn_1 fd disableBtn" value="Login">
                                            Forgot PIN </button>
                                    </div>
                                </div>
                            </div>
                            <p style={{color:"white",height:'5px',fontSize:'30px'}}>v{AppVersion}</p>
                        </div>
                    </div>
                    <div className="LogoLogBox">
                        <img src={logo} />
                    </div>
                   
                </div>
               
            </div>
        </>
    )
}
export default RegisterPage;