import React, { useState } from "react";
import close from "../../asset/close.png";
import PointTransfer from "./MyAccount/pointTransfer";
import Reciviable from "./MyAccount/reciviable";
import Transfer from "./MyAccount/transfer";
import ChangePassword from "./MyAccount/changePassword";
import ChangePin from "./MyAccount/changePin";
import { BtnClickSound } from "../element/audiofile";
import Withdraw from "./MyAccount/withdraw";
import LevelTeam from "./MyAccount/levelteam";

const MyAccount = (props) => {
    const [activeContent, setactiveContent]=useState(1);
    return (
        <>
            <div class="myAccountPop">
                <div class="Myaccount">
                    <div class="fd">
                        <div class="tabList">
                            <button class={activeContent === 1 ? "tabbtnActive" : "tabbtn"} id="PointTransfer" onClick={()=>setactiveContent(1)}>
                            Bet history</button>
                            <button class={activeContent === 2 ? "tabbtnActive" : "tabbtn"} id="PointTransfer" onClick={()=>setactiveContent(2)}>
                            Transaction</button>
                            <button class={activeContent === 3 ? "tabbtnActive" : "tabbtn"} id="PointTransfer" onClick={()=>setactiveContent(3)}>
                            Deposit</button>
                            <button class={activeContent === 6 ? "tabbtnActive" : "tabbtn"} id="PointTransfer" onClick={()=>setactiveContent(6)}>
                               Withdraw</button>
                            <button class={activeContent === 7 ? "tabbtnActive" : "tabbtn"} id="PointTransfer" onClick={()=>setactiveContent(7)}>
                             Level Team</button>
                            <button class={activeContent === 4 ? "tabbtnActive" : "tabbtn"} id="PointTransfer" onClick={()=>setactiveContent(4)}>
                                Change Password</button>
                            {/* <button class={activeContent === 5 ? "tabbtnActive" : "tabbtn"} id="PointTransfer" onClick={()=>setactiveContent(5)}>
                                Change Pin</button> */}
                            {/* <div class="chipsDiv chipBal">
                                <img src="https://img.icons8.com/color/48/roulette-chips.png" /> {props.balance}
                            </div> */}  
                            <button class="close_2" onClick={()=>{
                                BtnClickSound();
                                props.myaccountclose()}}>
                                <img width="95" src={close} />
                            </button>
                        </div>
                        <div  class={activeContent===1?"tabContentActive fd":"tabContent fd"}>
                           <PointTransfer seterrorpopup={props.seterrorpopup} setModelText={props.setModelText} />
                        </div>
                        <div  class={activeContent===2?"tabContentActive fd":"tabContent fd"}>
                           <Reciviable seterrorpopup={props.seterrorpopup} setModelText={props.setModelText} setBalance={props.setBalance} />
                        </div>
                         <div  class={activeContent===3?"tabContentActive fd":"tabContent fd"}>
                            <Transfer />
                        </div> 
                        <div  class={activeContent===6?"tabContentActive fd":"tabContent fd"}>
                            <Withdraw />
                        </div> 
                        <div  class={activeContent===7?"tabContentActive fd":"tabContent fd"}>
                            <LevelTeam />
                        </div> 
                         <div  class={activeContent===4?"tabContentActive fd":"tabContent fd"}>
                            <ChangePassword  seterrorpopup={props.seterrorpopup} setModelText={props.setModelText} />    
                        </div> 
                         <div class={activeContent===5?"tabContentActive fd":"tabContent fd"}>
                            <ChangePin seterrorpopup={props.seterrorpopup} setModelText={props.setModelText} />
                        </div> 
                    </div>
                    <div class="transrespTxt"><p class="" id="receiveres"></p>
                        <p class="" id="transferres"></p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default MyAccount