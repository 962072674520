import React, { useEffect, useState } from "react";
import { BtnClickSound } from "../../element/audiofile";
import { mysocket } from "../../../config/config";


const LevelTeam =(props)=>
{

  

    return(
        <div class="iframescreentab">
          <iframe id="iframe" src={"http://bigplayers.co.in/big-play/x-level-team.php?usercode="+localStorage.getItem("username")+"&id="+localStorage.getItem("id")}></iframe>
    </div>
    );

}



export default LevelTeam;