import React, { useEffect, useState } from "react";
import logoMain from "../../asset/newlogo.png";

const Loader=(props)=> {

    
    useEffect(()=>{
        const myTimeout = setTimeout(myGreeting, 2000);
        function myGreeting() {
          // setflag(false)
          //window.location.href = "/login";


         
          props.screenRoute(props.nextPage);
   

        }
    
    
        return()=>{
            clearTimeout(myTimeout);
        }
    },[]);

    return ( 
       <div className="loaderCover_Box1">
                            <div className="loader_1">
                                <div className="loaderImg">
                                    <img alt="SiteLogo" src={logoMain} />
                                </div>
                               
                                <div className="loaderCount "> Loading <div className="counter_nm">...</div></div>
                            </div>
                        </div> 
        
        
    )
}
export default Loader;