import React, { useEffect, useState } from "react";
import LoginMain from "./screen/login/loginMain"
import Loading from './screen/loading/loading';
import Lobby from './screen/lobby/Lobby';
import { GetSocket } from "./services/socket/socket";
import FunTargetTimer from "./screen/games/timer/funtargettimer/FunTargetTimer";
import Loader from "./screen/loading/loader";
import Errorpopup from "./screen/models/errorModel";
import AlreadyLoginModel from "./screen/models/alreadyLogin";
import NoInternetModel from "./screen/models/noInternetmodel";
import NewDeviceLoginAlert from "./screen/models/newDevicelogin";
import NoSleep from 'nosleep.js';
import AppUpdateModel from "./screen/models/appupdate";
import { AppVersion, domain, mysocket } from "./config/config";
import RegisterPage from "./screen/login/registerpage";

function App() {

  //App Config
  // const AppVersion = 1.0;

  //NAPI
  // const domain = "https://fungameasiagame.in/";

  //Main Exe
  // const domain = "https://fungameasia.co.in/";

  //bkk
  // const domain = "https://fungameasiagame.co.in/";


  const AppUpdateUrl = domain+ "update/fungamemain.apk";
  const ExeUpdateUrl = domain+ "update/fungamemain.exe";

  //App Var


  const [gbw, setgbw] = useState(2350);
  const [gbh, setgbh] = useState(1250);
  const [cnt, setcnt] = useState(0);
  const [flag, setflag] = useState(true);
  const [loginmain, setloginmain] = useState();
  const [serverTime,setServerTime] = useState("");
  const[errorpopup,seterrorpopup]=useState(false);
  const[alreadyLoginpoup,setAlreadyLoginPopup]=useState(false);
  const [modelText, setModelText] = useState("Invalid Username or password");
  const [activePage, setActivePage] = useState("");
  const [nextPage, setNextPage] = useState("/splash");
  const [noInternetpopup, setNointernetppopup] = useState(false);
  const [otherDeviceLogin, setOtherDeviceLogin] = useState(false);
  const [isMobile,setIsMobile] = useState(false);
  const [osname,setOsName] = useState("");
  const [updateAvailable, setIsAppUpdateAvailable] = useState(false);





  const screenRoute = (e) => {

    switch (e) {
      case '/splash':
        setActivePage("/splash");
        setMainElement(<Loading screenRoute={screenRoute} />)
        break;

      case '/login':
        setActivePage("/login");
        setMainElement(<LoginMain screenRoute={screenRoute} setModelText={setModelText} seterrorpopup={seterrorpopup} setAlreadyLoginPopup={setAlreadyLoginPopup} />)
        break;

        case '/register':
          setActivePage("/register");
          setMainElement(<RegisterPage screenRoute={screenRoute} setModelText={setModelText} seterrorpopup={seterrorpopup} setAlreadyLoginPopup={setAlreadyLoginPopup} />)
          break;

      case '/lobby':
        setActivePage("/lobby");
        setMainElement(<Lobby screenRoute={screenRoute} seterrorpopup={seterrorpopup} setModelText={setModelText} />)
        break;

      case '/loader':
        setActivePage("/loader");
        setMainElement(<Loader screenRoute={screenRoute} nextPage={nextPage} />)
        break;

      case '/funtargettimer':
        setActivePage("/fungametarget");
        setMainElement(<FunTargetTimer screenRoute={screenRoute} />)
        break;

      default:
        setActivePage("");
        setMainElement(<Loading screenRoute={screenRoute} />)
        break;
    }

  }


  const [mainElement, setMainElement] = useState(<Loading screenRoute={screenRoute} />)


  useEffect(() => {

    console.log("Looping");

    resize();

    mysocket.on("connect",(e)=>{

      console.log("Connected to Server");



      mysocket.emit("ws_AppVersion",AppVersion);

      mysocket.on("ws_AppVersion_respo",(e)=>{

        if(parseFloat(e["V"]) !== AppVersion)
        {
          console.log("App Update is Available");

          setIsAppUpdateAvailable(true);
        }
        else
        {
          setIsAppUpdateAvailable(false);
          console.log("App is Upto date");
        }

      });


      mysocket.on("ws_login_resp_glob",(e)=>{


        console.log("userLogin Status");

        console.log(e);

        console.log(activePage);
        console.log(localStorage.getItem("username"));

        if(localStorage.getItem("username")!==null && localStorage.getItem("username") !== undefined && e !== null && e !== undefined )
        {
          if(e.toString().toLowerCase()===localStorage.getItem("username").toLowerCase())
        {
          setMainElement(<LoginMain screenRoute={screenRoute} setModelText={setModelText} seterrorpopup={seterrorpopup} setAlreadyLoginPopup={setAlreadyLoginPopup} />)
          setOtherDeviceLogin(true);
        }
        }
        
        

      });  
     
    });



    const noSleep = new NoSleep();
    noSleep.enable();


    
    
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    
      setOsName("Android");
      setIsMobile(true);
  } else {
    
      setOsName("Windows");
      setIsMobile(false);
  }

    return () => {
      noSleep.disable();
      
      // clearInterval(myTimeout);
    }

  }, [cnt])




  const updateApp = ()=>{

    if(isMobile)
    {
      const apkUrl = AppUpdateUrl; 
      const downloadLink = document.createElement('a');
      downloadLink.href = apkUrl;
      downloadLink.target = '_blank';
      downloadLink.setAttribute('download', 'fungameasia.apk');

      const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
      });
      downloadLink.dispatchEvent(clickEvent);
    }
    else
    {
      const exeUrl = ExeUpdateUrl; // Live URL
      const downloadLink = document.createElement('a');
      downloadLink.href = exeUrl;
      downloadLink.target = '_blank';
 
      downloadLink.setAttribute('download', 'fungameasia.exe');

      const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
      });

      downloadLink.dispatchEvent(clickEvent);
    }

  }


  const resize = () => {
    let windHt;
    let windWd;
    setgbw(2350);
    setgbh(1250);
    windHt = window.innerHeight;
    windWd = window.innerWidth;
    // windHt = window.innerHeight - 10;
    // windWd = window.innerWidth - 10;

    console.log("Window windHt: " + windHt);
    console.log("Window windWd: " + windWd);

    let marg_left = (windHt * gbw / gbh - windWd) / 2;
    let marg_top = (windWd * gbh / gbw - windHt) / 2;
    let lndscp_1 = (windHt / gbw * gbh);
    let lndscp_2 = (lndscp_1 - windWd) / 2 * -1;
    console.log(lndscp_1)
    console.log(lndscp_2)
    let marg_left_lndscp = (Math.round(lndscp_2) - Math.round(lndscp_1)) / 2;
    console.log(marg_left_lndscp)

    let checkWid = windHt * (gbw / gbh);

    let gameBox = document.getElementById('gameBox');

    let gamewinMargin = (windWd - Math.round(checkWid)) / 2
    gameBox.style.transform = "scale(" + windWd / gbw + "," + windHt / gbh + ")";
  }

  const loginhandle = (name, pass) => {

    // window.location.href = "/lobby";



  }
  const loginMainClose = () => {
    window.location.href = "/login";
  }



  const forceLogout = ()=>
  {

    mysocket.emit("ws_force_login",localStorage.getItem("id"));

    mysocket.on("ws_force_login_resp",(e)=>{
      if(e)
      {
        console.log("Force Login Done");
      }
      else
      {
        console.log("Force Login Error");
      }
    });

  }





  return (
    <React.Fragment>
      <div id='gameBox'>
        <GetSocket setNointernetppopup={setNointernetppopup}/>


        {updateAvailable&&<AppUpdateModel updateApp={updateApp} />}

        {otherDeviceLogin&&<NewDeviceLoginAlert setOtherDeviceLogin={setOtherDeviceLogin}  screenRoute={screenRoute} modelText={modelText} />}


        {/* {activePage==="/splash" ? <Loading setActivePage={setActivePage} /> : ""}
        {activePage==="/login" ? <LoginMain  setActivePage={setActivePage}/> : ""}
        {activePage==="/lobby" ? <Lobby setActivePage={setActivePage} /> : ""}
        {activePage==="/funtargettimer" ? <FunTargetTimer /> : ""} */}

        {noInternetpopup&&<NoInternetModel seterrorpopup={seterrorpopup} modelText={"No Internet"}/>}

        {alreadyLoginpoup&&<AlreadyLoginModel forceLogout = {forceLogout} setAlreadyLoginPopup={setAlreadyLoginPopup}  screenRoute={screenRoute} modelText={modelText} />}
        {errorpopup&&<Errorpopup seterrorpopup={seterrorpopup} modelText={modelText} />}


        {mainElement}




        {/* <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginMain loginhandler={loginhandle} />} />
            <Route path="/lobby" element={<Lobby loginMainClose={loginMainClose} />} />
            <Route path="/funtargettimer" element={<FunTargetTimer />} />
            <Route exact path="/" element={<Loading />} />
          </Routes>
        </BrowserRouter> */}
      </div>
    </React.Fragment>
  );


}

export default App;


// { flag && <Loading/>}
// { <LoginMain/>}
// {loginmain &&<Lobby loginMainClose={loginMainClose}/>}
// {/* {<MyAccount/>} */}
