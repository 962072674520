import React, { useEffect, useState } from "react";
import { mysocket } from "../../../config/config";


const Transfer =(props)=>
{


    const openUrl = () => {
        const url = "upi://pay?pa=bighplayer1@ybl&pn=Jayshree food&cu=INR&tn=Recharge";
        const target = "_system"; // Open in the system browser
        const options = ""; // Optional parameters

        if (window.cordova) {
            window.cordova.InAppBrowser.open(url, target, options);
        } else {
            // If not running on a Cordova platform, fallback to window.open
            window.open(url, "_blank");
        }
    };

   

    return(
        <div class="iframescreentab" align="center">
        <iframe style={{height:'88%'}} id="iframe" src={"http://bigplayers.co.in/big-play/x-deposit.php?usercode="+localStorage.getItem("username")+"&id="+localStorage.getItem("id")}></iframe>
        {/* <iframe style={{height:'88%'}} id="iframe" src={"https://anandhiswankar.com/iframe/lol.php"}></iframe> */}
        <button className="phonepebtn" onClick={openUrl}>Open Phonepe</button>
   </div>
    );

}



export default Transfer;