import { io } from 'socket.io-client';


//Fungameasia Exe APK

// export const  mysocket = io('http://68.178.166.16:8989'); 
// export const domain = "https://fungameasia.co.in/";
// export const AppVersion = 1.1;

//Fungameasia NAPI

// export const  mysocket = io('http://68.178.166.16:9898'); 
// export const domain = "https://fungameasiagame.in/";
// export const AppVersion = 1.0;

//Fungameasia BKK
// export const  mysocket = io('http://132.148.77.106:9898/'); 
// export const domain = "https://fungameasiagame.co.in/";
// export const AppVersion = 1.1;


//big player 

export const  mysocket = io('http://118.139.167.9:3333'); 
export const domain = "https://bigplayers.co.in/";
export const AppVersion = 1.1;

